import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerServices from '../components/BannerServices'

import pic01 from '../assets/images/testing-service/ecologist-taking-samples-water-with-test-tube-from-city-river-determine-level-contamination-pollution.png'
import pic02 from '../assets/images/testing-service/pharmaceutical-chemist-examining-tomato-microbiology-experiment-typing-medical-information.png'
import pic03 from '../assets/images/testing-service/laboratory-worker-examining-green-substance-petri-dish-while-conducting-coronavirus-research.png'
import pic04 from '../assets/images/testing-service/back-view-doctor-holding-blood-sample.png'
import pic05 from '../assets/images/testing-service/pharmacist-filling-prescription-pharmacy-drugstore.png'
import pic06 from '../assets/images/background-testing.png'
import pic07 from '../assets/images/scientist-conducting-gel-electrophoresis-biological-process-as-part-coronavirus-research.png'

const TestingService = (props) => (
  <Layout>
    <Helmet>
      <title>Testing Service - Kimitech</title>
      <meta name="description" content="Testing Service Page" />
    </Helmet>

    <div id="main">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>TESTING</h1>
          </header>
          <span className="image main">
            <img src={pic07} alt="" />
          </span>
          <p>
            Kimitech International currently performs various qualification
            tests. We also offer several other non-certified tests and can set
            up your own test application based on customer requirements. Our
            experts ensure that application performance and integrity are
            maintained.
          </p>
          <p>
            We help our customers by:
            <br />
            <ul>
              <li>
                Ensuring compliance in an increasingly stringent regulatory
                environment.
              </li>
              <li>
                Leveraging our expertise across thousands of market-specific
                applications.
              </li>
              <li>
                Evaluating, developing and implementing a workflow from sample
                collection to final report.
              </li>
            </ul>
          </p>
        </div>
      </section>
      <section id="two" className="spotlights">
        <section>
          <img src={pic01} alt="" className="image" />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Water and Environmental</h3>
              </header>
              <p>
                Water is a vital resource not only for the general public but
                also for industries that depend on clean water supplies. As
                water pollution problems become more serious, regular water and
                sewerage inspections are one of the measures to comply with
                regulations. It is also important to ensure that these tests are
                reliable, and efficient and provide high-quality results.
              </p>
            </div>
          </div>
        </section>
        <section>
          <img src={pic02} alt="" className="image" />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Food and Feeds</h3>
              </header>
              <p>
                With the globalization of the food chain, ensuring food and
                feeds on the market, whether locally produced or imported, are
                sanitary and safe for consumption. Today's food and feed
                industry face increasing demands for more sensitive and
                productive analytical solutions. Our food quality analysis and
                testing are used throughout the food production chain including
                incoming inspection, new product development, hygiene and
                quality control and assurance, nutrition labelling and
                packaging.
              </p>
            </div>
          </div>
        </section>
        <section>
          <img src={pic03} alt="" className="image" />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Microbiology</h3>
              </header>
              <p>
                Microbiological testing services are important in many
                industries because culturing microorganisms such as bacteria,
                pathogens, yeasts and moulds can compromise product safety and
                pose risks to human health. Microbial growth is difficult to
                control even in the most sterile environments. Through our
                advanced microbiological testing labs, we can provide you with
                the right chemical analysis and analytical results you need, so
                you can make the right decisions to bring your products to
                market.
              </p>
            </div>
          </div>
        </section>
        <section>
          <img src={pic04} alt="" className="image" />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Industrial Hygiene, Blood & Urine</h3>
              </header>
              <p>
                Industrial hygiene and medical surveillance tests are important
                to ensure a safe working environment. Our test lab for material
                and industrial hygiene testing for a comprehensive range of
                hazardous substances in accordance with international standards.
                Based on the test results, we provide a plan for addressing
                issues found in the workplace.
              </p>
            </div>
          </div>
        </section>
        <section>
          <img src={pic05} alt="" className="image" />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Pharmaceuticals and Supplements</h3>
              </header>
              <p>
                We recognize that productivity and compliance are critical in
                the manufacturing and quality control of pharmaceuticals and
                nutraceuticals, and our laboratories have testing and analytical
                expertise to assist our customers. and there is a solution.
              </p>
              <ol>
                <li>Identify incoming raw materials</li>
                <li>
                  Monitor Volatile, Semi-Volatile, or Non-Volatile Contaminants
                </li>
                <li>Evaluation of the physical properties</li>
                <li>Determine content consistency</li>
              </ol>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default TestingService
